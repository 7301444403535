#contacts {
  background: #2c2828;
  width: 100vw;
  height: 100vh;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contacts_container {
  width: 65vw;
  height: 80vh;
  margin-left: 8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: #dad5d5;
  padding: 10px;
}
form {
  display: flex;
  flex-direction: column;
  color: rgb(57, 57, 58);
  font-weight: 600;
  font-size: 20px;
}
form > h4 {
  font-family: "Balsamiq Sans";

  color: #666464;
}
form > h1 {
  font-family: "Balsamiq Sans";

  color: blueviolet;
}
label {
  font-family: "Balsamiq Sans";

  margin-top: 15px;
  color: blueviolet;
}
input {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border: 1px solid blueviolet;
  margin-top: 10px;
  padding-left: 10px;
}
button {
  margin: 0 auto;
  margin-top: 30px;
  width: 80%;
  border-radius: 5px;
  background-color: blueviolet;
  color: white;
  font-weight: 700;
  height: 35px;
  font-size: 16px;
  outline: none;
  border: 1px solid;
  font-family: "Balsamiq Sans";
  letter-spacing: 2px;
}
.social_links {
  width: 22vw;
  height: 55vh;
  background-color: #402558;
  position: absolute;
  left: 10%;
  min-width: 230px;
  max-height: 340px;
  max-width: 300px;
  top: 23vh;
  box-shadow: 4px 2px 12px 0px #5a5656;
}
.social__icon {
  margin: 5px;
  color: white;
  padding: 0;
}
.social_links > h2 {
  color: white;
  margin: 15px;
  width: fit-content;
  font-size: 1.7em;
  font-family: system-ui;
}
.links_wrapper > a {
  display: flex;
  z-index: 999;
  align-items: center;
  color: white;
  font-size: 1.2em;
  font-weight: 500;
  font-family: system-ui;
  text-decoration: none;
}
textarea {
  width: 100%;
  height: 95px;
  border-radius: 5px;
  border: 1px solid blueviolet;
  margin-top: 10px;
  padding: 5px;
}
textarea:focus {
  outline-color: blueviolet;
}
input:focus {
  outline-color: blueviolet;
}
.links_wrapper {
  height: 70%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}
#user_name {
  text-transform: capitalize;
}
#user_message {
  text-transform: capitalize;
}
