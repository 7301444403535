.about_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(to bottom left, #2c2828 50%, #5b9ca9 50%);
}

.title-2 {
  width: 200px;
  position: absolute;
  z-index: 5;
  background: blueviolet;
  text-align: center;
  transform: rotateZ(-23deg);
  margin: 5%;
  margin-left: 7%;
  font-family: "Balsamiq Sans";
}

.title-2::before {
  width: 30px;
  height: 30px;
  content: "";
  background: #5b9ca9;
  position: absolute;
  z-index: 6;
  left: -22px;
  top: 5px;
  border-radius: 50%;
}
.title-2::after {
  width: 30px;
  height: 30px;
  content: "";
  background: #2c2828;
  position: absolute;
  z-index: 6;
  right: -22px;
  top: 5px;
  border-radius: 50%;
}

.about_sections_cont {
  width: 80vw;
  display: flex;
  margin-top: 10%;
  align-items: center;
  justify-content: space-evenly;
  flex-flow: wrap;
}
.about_section {
  margin: 1%;
  width: 10%;
  height: 35%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
.about_section > img {
  height: 120px;
  object-fit: contain;
  max-width: 100%;
}
.about_wrapper > h3 {
  font-size: 24px;
  width: 75%;
  margin: 0 auto;
  font-family: "Balsamiq Sans";
  color: white;
  text-align: end;
}
.tool_name {
  font-size: 0.9em;
  margin-top: 10px;
  font-family: "Balsamiq Sans";
  color: lightslategray;
  text-align: center;
}
.mob {
  display: none;
}
