@import url(https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hammersmith+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Potta+One&display=swap);
* {
  margin: 0;
}
.App {
  max-width: 100vw;
  max-height: 100vh;
  perspective: 500px;
  width: 100vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  transition: 0.5s;
  overflow: hidden;
  overflow-y: scroll;

  background: rgb(66 62 62);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}
.is_open {
  visibility: visible;
  transform: rotateY(-11deg) scale(0.69) translateX(90px);
}
::-webkit-scrollbar {
  display: none;
}
#about {
  transition-delay: 5s;
  transition: 0.5s;
}
#tools {
  transition: 0.5s;
}
.loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  -webkit-animation: load8 2s infinite cubic-bezier(0.39, 0.58, 0.57, 1);
          animation: load8 2s infinite cubic-bezier(0.39, 0.58, 0.57, 1);
  transition: opacity 0.3s;
}

.loader--hide {
  opacity: 0;
}

@-webkit-keyframes load8 {
  0% {
    transform: rotate(180deg);
  }
  25% {
    transform: rotate(360deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    transform: rotate(180deg);
  }
  25% {
    transform: rotate(360deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.load-cont {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  background: #2c2828;
  visibility: visible;
}
.load-cont-hide {
  visibility: hidden;
}

* {
  margin: 0;
  padding: 0;
}

.main_wrapper {
  display: flex;
  flex-direction: row;
  height: 100vh;
}
.color-1 {
  background: #2c2828;
  flex: 0.65 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
}
.text_wrapper {
  width: 65%;
}
.text_wrapper > h5 {
  font-size: 1.36rem;
  font-family: system-ui;
}
.text_wrapper > h1 {
  font-size: 5.3rem;
  font-family: "Balsamiq Sans", cursive;
  letter-spacing: -0.35rem;
  color: #5b9ca9;
}
.text_wrapper > h4 {
  font-size: 2.25rem;
  font-weight: 900;
  font-family: system-ui;
}

.color-2 {
  display: flex;
  height: 100vh;
  align-items: center;
  background: #5b9ca9;
  flex: 0.35 1;
}
.color-2 > img {
  width: 60%;
  border-radius: 18%;
  margin-left: -35%;
  box-shadow: inset 0px 0px 110px whitesmoke;
}
.title-1 {
  width: 200px;
  position: absolute;
  z-index: 8;
  background: blueviolet;
  text-align: center;
  transform: rotateZ(23deg);
  margin: 3%;
  margin-left: 60%;
  font-family: "Balsamiq Sans";
}

.about_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(to bottom left, #2c2828 50%, #5b9ca9 50%);
}

.title-2 {
  width: 200px;
  position: absolute;
  z-index: 5;
  background: blueviolet;
  text-align: center;
  transform: rotateZ(-23deg);
  margin: 5%;
  margin-left: 7%;
  font-family: "Balsamiq Sans";
}

.title-2::before {
  width: 30px;
  height: 30px;
  content: "";
  background: #5b9ca9;
  position: absolute;
  z-index: 6;
  left: -22px;
  top: 5px;
  border-radius: 50%;
}
.title-2::after {
  width: 30px;
  height: 30px;
  content: "";
  background: #2c2828;
  position: absolute;
  z-index: 6;
  right: -22px;
  top: 5px;
  border-radius: 50%;
}

.about_sections_cont {
  width: 80vw;
  display: flex;
  margin-top: 10%;
  align-items: center;
  justify-content: space-evenly;
  flex-flow: wrap;
}
.about_section {
  margin: 1%;
  width: 10%;
  height: 35%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
.about_section > img {
  height: 120px;
  object-fit: contain;
  max-width: 100%;
}
.about_wrapper > h3 {
  font-size: 24px;
  width: 75%;
  margin: 0 auto;
  font-family: "Balsamiq Sans";
  color: white;
  text-align: end;
}
.tool_name {
  font-size: 0.9em;
  margin-top: 10px;
  font-family: "Balsamiq Sans";
  color: lightslategray;
  text-align: center;
}
.mob {
  display: none;
}

.bar {
  margin: 10px;
  max-width: 200px;
  width: 200px;
  padding: 10px;
  border: 1px solid #e2e0db;
  border-radius: 7px;
  background: #ffffff;
}
.bar__info {
  display: flex;
  align-items: center;
  padding: 5px 0px 5px 0px;
}
.bar__info > img {
  height: 35px;
  padding: 5px;
}
.bar__info > h2 {
  font-family: "Hammersmith One", sans-serif;
  color: #2c2828;
}

#latest-work {
  min-height: 100vh;
  max-height: 100vh;
  height: 100vw;
  width: 100vw;
  padding-right: 55px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: scroll;
  background: linear-gradient(
    0deg,
    rgba(44, 40, 40, 1) 20%,
    rgba(91, 156, 169, 1) 100%
  );
  transition: 0.5s;
}
.works_container {
  display: flex;
  width: 99vw;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 auto;
  flex-wrap: wrap;
  padding: 100px;
}
.slider {
  display: flex;
}

.like_button {
  color: #111;
}
.like_button:hover {
  color: red !important;
}
.card_button_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.MuiPaper-root {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  width: 270px;
  height: 330px;
  margin: 20px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999999;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #bbb;
  color: black;
  border-radius: 5px;
}

/* Style the back side */
.flip-card-back {
  background-color: #5b9ca9;
  color: white;
  transform: rotateY(180deg);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: start;
}
ul {
  width: 100%;
  list-style-type: none;
  padding: 10px;
}
ul li {
  margin-bottom: 10px;
  font-family: system-ui;
}
.flip-card-back_inside {
  display: flex;
  width: 100%;
  height: 90%;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  font-family: system-ui;
}
.visit_link {
  font-size: 20px;
  text-decoration: none;
  font-weight: 600;
  color: rgb(36, 34, 34);
  padding: 5px;
  font-family: system-ui;
  width: 30%;
  text-align: center;
  height: 25px;
  background: #f5f5f554;
  border-radius: 10px;
  margin: 0 auto;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.front {
  margin-bottom: 10px;
}

.nav {
  position: fixed;
  top: 0;
  z-index: 9;
  display: flex;
  object-fit: contain;
  justify-content: space-evenly;
  align-items: center;
  width: 0px;
  height: 100vh;
  transition-timing-function: step-start;
  transition: all 0.8s;
}
.nav__black {
  background-color: #5b9ca9;
}
.nav.nav__black::after {
  content: ">";
  width: 100px;
  height: 100px;
  position: absolute;
  background: green;
  right: -31px;
  border-bottom-right-radius: 100%;
  border-top-right-radius: 100%;
  z-index: -1;
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
  color: white;
  font-size: 25px;
  padding: 10px;
  font-weight: bolder;
}
.btn_container {
  display: flex;
  left: 0px;
  align-items: center;
  justify-content: space-evenly;
  width: 250px;
  height: 100%;
  flex-direction: column;
  position: fixed;
  box-shadow: rgb(91, 156, 169) 1px 0px 4px 0px;
  /*animation: anime 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);*/
  transition: visibility 0.3s;
  transition: 0.5s;
  transform: translateX(-300px);
}
.container_1 {
  flex: 0.25 1;
  padding-left: 20px;
}
.container_2 {
  flex: 0.75 1;
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.btn_cont {
  text-decoration: none;
  font-family: "Balsamiq Sans";
  color: white;
  padding: 20px;
  cursor: pointer;
}
.main_link {
  font-family: "Potta One", cursive;
  color: #111;
}
.btn_cont {
  width: 100%;
  height: 25%;
  background: #2c2828;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: white;
  border: 1px solid black;
}

.btn_cont:hover {
  color: #5b9ca9;
  font-size: 20px;
  box-shadow: inset 0px 0px 7px #5b9ca9;
}
.sidebar__cont > a > img {
  width: 100%;
  transition: ease-in 0.3s;
}

.sidebar__cont > a > img:hover {
  background: #5b9ca9;
}
.sidebar_social > a {
  display: contents;
}

.sidebar_social {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.sidebar__cont {
  width: 90px;
  height: 100vh;
  position: fixed;
  right: 0;
  background: #1b1a1a;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 0px 0px 7px 0px black;
}

#menu_icon {
  width: 60%;
  height: 15%;
  color: blueviolet;
}
#menu_icon:hover {
  color: #5b9ca9;
}
.MuiSvgIcon-root {
  width: 1.2em;
  height: 1.2em;
  padding: 5px;
  padding: 10px;
}
.MuiSvgIcon-root:hover {
  color: #5b9ca9;
  transition: ease-in 0.3s;
}
.open {
  box-shadow: inset 0px 0px 7px rebeccapurple;
  font-size: 18px;
  color: blueviolet;
  border: none;
  border: 1px solid;
}
.open:hover {
  color: blueviolet;
  border: 1px solid;
  box-shadow: inset 0px 0px 7px rebeccapurple;
  font-size: 20px;
}

@-webkit-keyframes anime {
  0% {
    transform: translateX(-300px);
  }

  50% {
    transform: translateX(-150px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes anime {
  0% {
    transform: translateX(-300px);
  }

  50% {
    transform: translateX(-150px);
  }
  100% {
    transform: translateX(0px);
  }
}

.link_svg {
  color: rebeccapurple;
}

#contacts {
  background: #2c2828;
  width: 100vw;
  height: 100vh;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contacts_container {
  width: 65vw;
  height: 80vh;
  margin-left: 8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: #dad5d5;
  padding: 10px;
}
form {
  display: flex;
  flex-direction: column;
  color: rgb(57, 57, 58);
  font-weight: 600;
  font-size: 20px;
}
form > h4 {
  font-family: "Balsamiq Sans";

  color: #666464;
}
form > h1 {
  font-family: "Balsamiq Sans";

  color: blueviolet;
}
label {
  font-family: "Balsamiq Sans";

  margin-top: 15px;
  color: blueviolet;
}
input {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border: 1px solid blueviolet;
  margin-top: 10px;
  padding-left: 10px;
}
button {
  margin: 0 auto;
  margin-top: 30px;
  width: 80%;
  border-radius: 5px;
  background-color: blueviolet;
  color: white;
  font-weight: 700;
  height: 35px;
  font-size: 16px;
  outline: none;
  border: 1px solid;
  font-family: "Balsamiq Sans";
  letter-spacing: 2px;
}
.social_links {
  width: 22vw;
  height: 55vh;
  background-color: #402558;
  position: absolute;
  left: 10%;
  min-width: 230px;
  max-height: 340px;
  max-width: 300px;
  top: 23vh;
  box-shadow: 4px 2px 12px 0px #5a5656;
}
.social__icon {
  margin: 5px;
  color: white;
  padding: 0;
}
.social_links > h2 {
  color: white;
  margin: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.7em;
  font-family: system-ui;
}
.links_wrapper > a {
  display: flex;
  z-index: 999;
  align-items: center;
  color: white;
  font-size: 1.2em;
  font-weight: 500;
  font-family: system-ui;
  text-decoration: none;
}
textarea {
  width: 100%;
  height: 95px;
  border-radius: 5px;
  border: 1px solid blueviolet;
  margin-top: 10px;
  padding: 5px;
}
textarea:focus {
  outline-color: blueviolet;
}
input:focus {
  outline-color: blueviolet;
}
.links_wrapper {
  height: 70%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}
#user_name {
  text-transform: capitalize;
}
#user_message {
  text-transform: capitalize;
}

@media only screen and (max-width: 1200px) {
  .title-2 {
    margin: 15%;
    margin-left: 10%;
  }
  .color-2 > img {
    width: 60%;
    border-radius: 18%;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 900px) {
  .contacts_container {
    width: 70vw;
    margin-top: 55px;
    margin-left: 0px;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    background: #dad5d5;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0px;
    padding-top: 60px;
  }
  .social_links {
    background-color: #402558;
    margin-top: 20px;
    max-height: 340px;
    box-shadow: 4px 2px 12px 0px #5a5656;
    position: inherit;
    height: unset;
    width: 100%;
    max-width: 100vw;
  }
  #latest-work {
    padding: 55px 0px;
  }
  .pc {
    display: none;
  }
  .mob {
    display: flex;
    width: 100vw;
  }
  .bar {
    width: 35%;
    max-width: 40%;
  }

  .sidebar__cont {
    width: 100vw;
    height: 55px;
    position: fixed;
    top: 0;
    left: 0;
    background: #1b1a1a;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    box-shadow: 0px 0px 7px 0px black;
  }
  .sidebar__cont > a > img {
    width: unset;
    height: 55px;
  }

  .sidebar_social {
    display: none;
  }

  .btn_container {
    display: flex;
    left: 2px;
    align-items: center;
    justify-content: space-evenly;
    width: 150px;
    margin-top: 12%;
    height: 80%;
    flex-direction: column;
    position: fixed;
    box-shadow: rgb(91 156 169) 1px 0px 4px 0px;
    -webkit-animation: anime 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);
            animation: anime 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);
    transition: visibility 0.3s;
  }
  #menu_icon {
    width: unset;
    height: 100%;
    color: blueviolet;
  }
  .title-1 {
    margin: 23%;
    margin-left: 50%;
  }
  .title-2 {
    margin: 20%;
    margin-left: 3%;
  }
  .color-2 > img {
    width: 70%;
    border-radius: 18%;
    margin-left: 5%;
  }
}

@media only screen and (max-width: 600px) {
  #latest-work {
    padding: 55px 0px;
  }
  .pc {
    display: none;
  }
  .mob {
    display: flex;
    width: 100vw;
  }
  .bar {
    width: 35%;
    max-width: 40%;
  }

  .sidebar__cont {
    width: 100vw;
    height: 55px;
    position: fixed;
    top: 0;
    left: 0;
    background: #1b1a1a;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    box-shadow: 0px 0px 7px 0px black;
  }
  .sidebar__cont > img {
    height: 100%;
    transition: ease-in 0.3s;
    object-fit: contain;
    width: unset;
  }

  .sidebar_social {
    display: none;
  }

  .btn_container {
    display: flex;
    left: 2px;
    align-items: center;
    justify-content: space-evenly;
    width: 150px;
    margin-top: 12%;
    height: 80%;
    flex-direction: column;
    position: fixed;
    box-shadow: rgb(91 156 169) 1px 0px 4px 0px;
    -webkit-animation: anime 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);
            animation: anime 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);
    transition: visibility 0.3s;
  }
  #menu_icon {
    width: unset;
    height: 100%;
    color: blueviolet;
  }

  .about_wrapper > h3 {
    z-index: 98;
  }
  .title-1 {
    margin: 30%;
    margin-left: 50%;
  }
  .title-2 {
    top: 4ch;
    left: -6vw;
    display: none;
  }
  .text_wrapper {
    margin: 0 auto;
    width: 80%;
  }
  .color-2 > img {
    width: unset;
    height: 25%;
    margin-left: -20%;
  }
  .sidebar__cont > a > img {
    width: unset;
    height: 55px;
  }
  .contacts_container {
    width: 94vw;
    margin-left: 3vw;
    margin-top: 55px;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    background: #dad5d5;
    margin-left: unset;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0px;
    padding-top: 60px;
  }
  .social_links {
    background-color: #402558;
    margin-top: 20px;
    max-height: 340px;
    box-shadow: 4px 2px 12px 0px #5a5656;
    position: inherit;
    height: unset;
    width: 100%;
    max-width: 100vw;
  }
  form {
    z-index: 9;
  }
}

@media only screen and (max-width: 450px) {
  #latest-work {
    padding: 55px 0px;
  }
  .sidebar__cont {
    width: 100vw;
    height: 55px;
    position: fixed;
    top: 0;
    left: 0;
    background: #1b1a1a;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    box-shadow: 0px 0px 7px 0px black;
  }
  .sidebar__cont > img {
    height: 100%;
    transition: ease-in 0.3s;
    object-fit: contain;
    width: unset;
  }

  .sidebar_social {
    display: none;
  }

  .btn_container {
    display: flex;
    left: 2px;
    top: 10px;

    align-items: center;
    justify-content: space-evenly;
    width: 120px;
    margin-top: 12%;
    height: 40%;
    flex-direction: column;
    position: fixed;
    box-shadow: rgb(91, 156, 169) 1px 0px 4px 0px;
    -webkit-animation: anime 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);
            animation: anime 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);
    transition: visibility 0.3s;
  }
  #menu_icon {
    width: unset;
    height: 100%;
    color: blueviolet;
  }

  .about_wrapper > h3 {
    z-index: 98;
  }
  .title-1 {
    margin: 20%;
    margin-left: 35%;
    box-shadow: 3px 3px 0px #fff;
    color: #2c2828;
  }
  .title-2 {
    top: 4ch;
    left: -6vw;
  }
  .text_wrapper {
    margin: 0 auto;
    width: 80%;
  }
  .color-2 > img {
    width: unset;
    height: 30%;
    position: absolute;
    top: 18%;
    left: 70%;
  }
  .color-1 {
    background: #2c2828;
    flex: 0.55 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #fff;
  }

  .color-2 {
    display: flex;
    height: 100vh;
    align-items: center;
    background: #5b9ca9;
    flex: 0.45 1;
  }
  .text_wrapper {
    position: absolute;
    left: 30px;
  }
  .text_wrapper > h1 {
    text-shadow: 0px 0px 15px #2c2828;
  }
  .text_wrapper > h4 {
    text-shadow: 2px 2px #5b9ca9;
  }
  .text_wrapper > h5 {
    text-shadow: 3px 3px 3px rebeccapurple;
    font-size: 2.5em;
  }
  .works_container {
    overflow-x: scroll;
    margin-bottom: 505px;
    padding: 0px;
  }

  .about_section {
    margin: 1%;
    width: 20%;
    height: 35%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    max-height: 18%;
  }
  .sidebar__cont > a > img {
    width: unset;
    height: 55px;
  }
  .contacts_container {
    width: 100vw;
    margin-top: 55px;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    background: #dad5d5;
    margin-left: unset;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0px;
    padding-top: 60px;
  }
  .social_links {
    background-color: #402558;
    margin-top: 20px;
    max-height: 340px;
    box-shadow: 4px 2px 12px 0px #5a5656;
    position: inherit;
    height: unset;
    width: 100%;
    max-width: 100vw;
  }
  form {
    z-index: 9;
  }
}

