@media only screen and (max-width: 1200px) {
  .title-2 {
    margin: 15%;
    margin-left: 10%;
  }
  .color-2 > img {
    width: 60%;
    border-radius: 18%;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 900px) {
  .contacts_container {
    width: 70vw;
    margin-top: 55px;
    margin-left: 0px;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    background: #dad5d5;
    height: fit-content;
    padding: 0px;
    padding-top: 60px;
  }
  .social_links {
    background-color: #402558;
    margin-top: 20px;
    max-height: 340px;
    box-shadow: 4px 2px 12px 0px #5a5656;
    position: inherit;
    height: unset;
    width: 100%;
    max-width: 100vw;
  }
  #latest-work {
    padding: 55px 0px;
  }
  .pc {
    display: none;
  }
  .mob {
    display: flex;
    width: 100vw;
  }
  .bar {
    width: 35%;
    max-width: 40%;
  }

  .sidebar__cont {
    width: 100vw;
    height: 55px;
    position: fixed;
    top: 0;
    left: 0;
    background: #1b1a1a;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    box-shadow: 0px 0px 7px 0px black;
  }
  .sidebar__cont > a > img {
    width: unset;
    height: 55px;
  }

  .sidebar_social {
    display: none;
  }

  .btn_container {
    display: flex;
    left: 2px;
    align-items: center;
    justify-content: space-evenly;
    width: 150px;
    margin-top: 12%;
    height: 80%;
    flex-direction: column;
    position: fixed;
    box-shadow: rgb(91 156 169) 1px 0px 4px 0px;
    animation: anime 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);
    transition: visibility 0.3s;
  }
  #menu_icon {
    width: unset;
    height: 100%;
    color: blueviolet;
  }
  .title-1 {
    margin: 23%;
    margin-left: 50%;
  }
  .title-2 {
    margin: 20%;
    margin-left: 3%;
  }
  .color-2 > img {
    width: 70%;
    border-radius: 18%;
    margin-left: 5%;
  }
}

@media only screen and (max-width: 600px) {
  #latest-work {
    padding: 55px 0px;
  }
  .pc {
    display: none;
  }
  .mob {
    display: flex;
    width: 100vw;
  }
  .bar {
    width: 35%;
    max-width: 40%;
  }

  .sidebar__cont {
    width: 100vw;
    height: 55px;
    position: fixed;
    top: 0;
    left: 0;
    background: #1b1a1a;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    box-shadow: 0px 0px 7px 0px black;
  }
  .sidebar__cont > img {
    height: 100%;
    transition: ease-in 0.3s;
    object-fit: contain;
    width: unset;
  }

  .sidebar_social {
    display: none;
  }

  .btn_container {
    display: flex;
    left: 2px;
    align-items: center;
    justify-content: space-evenly;
    width: 150px;
    margin-top: 12%;
    height: 80%;
    flex-direction: column;
    position: fixed;
    box-shadow: rgb(91 156 169) 1px 0px 4px 0px;
    animation: anime 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);
    transition: visibility 0.3s;
  }
  #menu_icon {
    width: unset;
    height: 100%;
    color: blueviolet;
  }

  .about_wrapper > h3 {
    z-index: 98;
  }
  .title-1 {
    margin: 30%;
    margin-left: 50%;
  }
  .title-2 {
    top: 4ch;
    left: -6vw;
    display: none;
  }
  .text_wrapper {
    margin: 0 auto;
    width: 80%;
  }
  .color-2 > img {
    width: unset;
    height: 25%;
    margin-left: -20%;
  }
  .sidebar__cont > a > img {
    width: unset;
    height: 55px;
  }
  .contacts_container {
    width: 94vw;
    margin-left: 3vw;
    margin-top: 55px;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    background: #dad5d5;
    margin-left: unset;
    height: fit-content;
    padding: 0px;
    padding-top: 60px;
  }
  .social_links {
    background-color: #402558;
    margin-top: 20px;
    max-height: 340px;
    box-shadow: 4px 2px 12px 0px #5a5656;
    position: inherit;
    height: unset;
    width: 100%;
    max-width: 100vw;
  }
  form {
    z-index: 9;
  }
}

@media only screen and (max-width: 450px) {
  #latest-work {
    padding: 55px 0px;
  }
  .sidebar__cont {
    width: 100vw;
    height: 55px;
    position: fixed;
    top: 0;
    left: 0;
    background: #1b1a1a;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    box-shadow: 0px 0px 7px 0px black;
  }
  .sidebar__cont > img {
    height: 100%;
    transition: ease-in 0.3s;
    object-fit: contain;
    width: unset;
  }

  .sidebar_social {
    display: none;
  }

  .btn_container {
    display: flex;
    left: 2px;
    top: 10px;

    align-items: center;
    justify-content: space-evenly;
    width: 120px;
    margin-top: 12%;
    height: 40%;
    flex-direction: column;
    position: fixed;
    box-shadow: rgb(91, 156, 169) 1px 0px 4px 0px;
    animation: anime 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);
    transition: visibility 0.3s;
  }
  #menu_icon {
    width: unset;
    height: 100%;
    color: blueviolet;
  }

  .about_wrapper > h3 {
    z-index: 98;
  }
  .title-1 {
    margin: 20%;
    margin-left: 35%;
    box-shadow: 3px 3px 0px #fff;
    color: #2c2828;
  }
  .title-2 {
    top: 4ch;
    left: -6vw;
  }
  .text_wrapper {
    margin: 0 auto;
    width: 80%;
  }
  .color-2 > img {
    width: unset;
    height: 30%;
    position: absolute;
    top: 18%;
    left: 70%;
  }
  .color-1 {
    background: #2c2828;
    flex: 0.55;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #fff;
  }

  .color-2 {
    display: flex;
    height: 100vh;
    align-items: center;
    background: #5b9ca9;
    flex: 0.45;
  }
  .text_wrapper {
    position: absolute;
    left: 30px;
  }
  .text_wrapper > h1 {
    text-shadow: 0px 0px 15px #2c2828;
  }
  .text_wrapper > h4 {
    text-shadow: 2px 2px #5b9ca9;
  }
  .text_wrapper > h5 {
    text-shadow: 3px 3px 3px rebeccapurple;
    font-size: 2.5em;
  }
  .works_container {
    overflow-x: scroll;
    margin-bottom: 505px;
    padding: 0px;
  }

  .about_section {
    margin: 1%;
    width: 20%;
    height: 35%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    max-height: 18%;
  }
  .sidebar__cont > a > img {
    width: unset;
    height: 55px;
  }
  .contacts_container {
    width: 100vw;
    margin-top: 55px;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    background: #dad5d5;
    margin-left: unset;
    height: fit-content;
    padding: 0px;
    padding-top: 60px;
  }
  .social_links {
    background-color: #402558;
    margin-top: 20px;
    max-height: 340px;
    box-shadow: 4px 2px 12px 0px #5a5656;
    position: inherit;
    height: unset;
    width: 100%;
    max-width: 100vw;
  }
  form {
    z-index: 9;
  }
}
