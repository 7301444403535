@import url("https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@700&display=swap");

* {
  margin: 0;
  padding: 0;
}

.main_wrapper {
  display: flex;
  flex-direction: row;
  height: 100vh;
}
.color-1 {
  background: #2c2828;
  flex: 0.65;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
}
.text_wrapper {
  width: 65%;
}
.text_wrapper > h5 {
  font-size: 1.36rem;
  font-family: system-ui;
}
.text_wrapper > h1 {
  font-size: 5.3rem;
  font-family: "Balsamiq Sans", cursive;
  letter-spacing: -0.35rem;
  color: #5b9ca9;
}
.text_wrapper > h4 {
  font-size: 2.25rem;
  font-weight: 900;
  font-family: system-ui;
}

.color-2 {
  display: flex;
  height: 100vh;
  align-items: center;
  background: #5b9ca9;
  flex: 0.35;
}
.color-2 > img {
  width: 60%;
  border-radius: 18%;
  margin-left: -35%;
  box-shadow: inset 0px 0px 110px whitesmoke;
}
.title-1 {
  width: 200px;
  position: absolute;
  z-index: 8;
  background: blueviolet;
  text-align: center;
  transform: rotateZ(23deg);
  margin: 3%;
  margin-left: 60%;
  font-family: "Balsamiq Sans";
}
