@import url("https://fonts.googleapis.com/css2?family=Potta+One&display=swap");

.nav {
  position: fixed;
  top: 0;
  z-index: 9;
  display: flex;
  object-fit: contain;
  justify-content: space-evenly;
  align-items: center;
  width: 0px;
  height: 100vh;
  transition-timing-function: step-start;
  transition: all 0.8s;
}
.nav__black {
  background-color: #5b9ca9;
}
.nav.nav__black::after {
  content: ">";
  width: 100px;
  height: 100px;
  position: absolute;
  background: green;
  right: -31px;
  border-bottom-right-radius: 100%;
  border-top-right-radius: 100%;
  z-index: -1;
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
  color: white;
  font-size: 25px;
  padding: 10px;
  font-weight: bolder;
}
.btn_container {
  display: flex;
  left: 0px;
  align-items: center;
  justify-content: space-evenly;
  width: 250px;
  height: 100%;
  flex-direction: column;
  position: fixed;
  box-shadow: rgb(91, 156, 169) 1px 0px 4px 0px;
  /*animation: anime 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);*/
  transition: visibility 0.3s;
  transition: 0.5s;
  transform: translateX(-300px);
}
.container_1 {
  flex: 0.25;
  padding-left: 20px;
}
.container_2 {
  flex: 0.75;
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.btn_cont {
  text-decoration: none;
  font-family: "Balsamiq Sans";
  color: white;
  padding: 20px;
  cursor: pointer;
}
.main_link {
  font-family: "Potta One", cursive;
  color: #111;
}
.btn_cont {
  width: 100%;
  height: 25%;
  background: #2c2828;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: white;
  border: 1px solid black;
}

.btn_cont:hover {
  color: #5b9ca9;
  font-size: 20px;
  box-shadow: inset 0px 0px 7px #5b9ca9;
}
.sidebar__cont > a > img {
  width: 100%;
  transition: ease-in 0.3s;
}

.sidebar__cont > a > img:hover {
  background: #5b9ca9;
}
.sidebar_social > a {
  display: contents;
}

.sidebar_social {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.sidebar__cont {
  width: 90px;
  height: 100vh;
  position: fixed;
  right: 0;
  background: #1b1a1a;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 0px 0px 7px 0px black;
}

#menu_icon {
  width: 60%;
  height: 15%;
  color: blueviolet;
}
#menu_icon:hover {
  color: #5b9ca9;
}
.MuiSvgIcon-root {
  width: 1.2em;
  height: 1.2em;
  padding: 5px;
  padding: 10px;
}
.MuiSvgIcon-root:hover {
  color: #5b9ca9;
  transition: ease-in 0.3s;
}
.open {
  box-shadow: inset 0px 0px 7px rebeccapurple;
  font-size: 18px;
  color: blueviolet;
  border: none;
  border: 1px solid;
}
.open:hover {
  color: blueviolet;
  border: 1px solid;
  box-shadow: inset 0px 0px 7px rebeccapurple;
  font-size: 20px;
}

@keyframes anime {
  0% {
    transform: translateX(-300px);
  }

  50% {
    transform: translateX(-150px);
  }
  100% {
    transform: translateX(0px);
  }
}

.link_svg {
  color: rebeccapurple;
}
